@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.good-font-1 {
  font-family: "Lora", serif !important;
}

@font-face {
  font-family: "Madani Arabic Black";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Black.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-2 {
  font-family: "Madani Arabic Black", sans-serif;
}

@font-face {
  font-family: "Madani Arabic extra-light";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Extra\ Light.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-3 {
  font-family: "Madani Arabic extra-light", sans-serif;
}

@font-face {
  font-family: "Madani Arabic light";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Light.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-4 {
  font-family: "Madani Arabic light", sans-serif;
}

@font-face {
  font-family: "Madani Arabic thin";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Thin.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-5 {
  font-family: "Madani Arabic thin", sans-serif;
}

@font-face {
  font-family: "Madani Arabic regular";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Regular.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-6 {
  font-family: "Madani Arabic regular", sans-serif;
}

@font-face {
  font-family: "Madani Arabic medium";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Medium.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-7 {
  font-family: "Madani Arabic medium", sans-serif;
}

@font-face {
  font-family: "Madani Arabic semi-bold";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Semi\ Bold.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-8 {
  font-family: "Madani Arabic semi-bold", sans-serif;
}

@font-face {
  font-family: "Madani Arabic bold";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Bold.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-9 {
  font-family: "Madani Arabic bold", sans-serif;
}

@font-face {
  font-family: "Madani Arabic extra-bold";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Extra\ Bold.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-10 {
  font-family: "Madani Arabic extra-bold", sans-serif;
}

@font-face {
  font-family: "Madani Arabic variable";
  src: url("./fonts/Madani-Arabic-Font-Family/Madani\ Arabic\ Variable.ttf")
    format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-11 {
  font-family: "Madani Arabic variable", sans-serif;
}

@font-face {
  font-family: "korolev";
  src: url("./fonts/Korolev_Condensed_W04_Bold.ttf") format("truetype");
  /* Add other font formats as needed, such as woff2, ttf, etc. */
}

.good-font-12 {
  font-family: "korolev", sans-serif;
}

.scroll-bar-cool-1::-webkit-scrollbar {
  width: 5px;
  background-color: #cccccc00;
}

.scroll-bar-cool-1::-webkit-scrollbar-thumb {
  background-color: #d8ff00;
  border-radius: 12px;
}

.scroll-bar-cool-2::-webkit-scrollbar {
  width: 3px;
  background-color: #cccccc00;
}

.scroll-bar-cool-2::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 12px;
}

.scroll-bar-cool-3::-webkit-scrollbar {
  width: 3px;
  background-color: #cccccc00;
}

.scroll-bar-cool-3::-webkit-scrollbar-thumb {
  background-color: #45464a;
  border-radius: 12px;
}

.scroll-bar-cool-4::-webkit-scrollbar {
  width: 3px;
  background-color: #cccccc00;
}

.scroll-bar-cool-4::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 12px;
}


.scroll-bar-cool-5::-webkit-scrollbar {
  width: 3px;
  background-color: #cccccc00;
}

.scroll-bar-cool-5::-webkit-scrollbar-thumb {
  background-color: #262626;
  border-radius: 12px;
}

.menuuuu {
  top: calc((100vh - 500px) / 2);
  left: calc((100vw - 1200px) / 2);
}

.menu-1 {
  top: calc((100vh - 500px) / 2 + 30px);
  left: calc((100vw - 1200px) / 2);
}
